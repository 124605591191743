import { Button, makeStyles, Field, Input, Spinner } from '@fluentui/react-components';
import Axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    marginBottom: '10px',
  },
});

function Login({ notify }) {
  const navigate = useNavigate();
  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      if (!values.email) {
        notify("Invalid Email");
        return;
      }
      if (!values.password) {
        notify("Invalid Password");
        return;
      }
      await login(values.email, values.password);
    },
  });

  const login = async (email, password) => {
    setLoading(true);
    let url = `/api/login`;

    let axios = Axios.create({
      responseType: "json",
      headers: {
        'Content-Type': "application/json",
      },
    });

    let search = window.location.search;
    let returnUrl = null;
    if (search && search.startsWith('?') && search.length > 0) {
      search = search.substring(1);
      if (search.length > 0 && search.includes('returnUrl')) {
        const index = search.indexOf('returnUrl=');
        returnUrl = search.substring(index + 1);
      }
    }

    if (returnUrl && returnUrl.length > 0) {
      url += `&returnUrl=${returnUrl}`;
    }

    axios.post(url, { username: email, password: password })
      .then(result => {
        if (result.data.success) {
          window.location.reload('/');
        } else {
          notify(result.data.error);
          setLoading(false);
        }
      });
  }

  return (
    <>
      {loading && <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}><Spinner style={{ left: '50%', marginLeft: '-4em' }} /></div>}
      <div className={styles.root}>
        <form style={{ marginBottom: 15 }}>
          <Field className={styles.field}>
            <Input value={loginForm.values.email} onChange={(ev, data) => loginForm.setFieldValue('email', data.value)} placeholder='Enter your Email' type='email' />
          </Field>
          <Field className={styles.field}>
            <Input value={loginForm.values.password} onChange={(ev, data) => loginForm.setFieldValue('password', data.value)} placeholder='Enter your Password' type='password' />
          </Field>
        </form>
        <Button appearance='primary' onClick={loginForm.submitForm}>Log In</Button>
        <Button onClick={() => navigate('/forgot-password')} appearance='transparent' style={{ color: 'white' }}>Forgot Password?</Button>
      </div>
    </>
  );
}

export default Login;
