import { Button, makeStyles, Field, Input, Tag } from '@fluentui/react-components';
import Axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    marginBottom: '10px',
  },
});

function ResetPassword({ notify }) {
  const navigate = useNavigate();
  const styles = useStyles();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed && parsed['code']) {
      setCode(parsed['code']);
    }
    if (parsed && parsed['email']) {
      setEmail(parsed['email']);
    }
  }, []);

  const submit = async () => {
    if (!email) {
      notify('Email is Required');
      return;
    }
    if (!code) {
      notify('Password reset Token is Required');
      return;
    }
    if (!password) {
      notify('Password is Required');
      return;
    }
    if (!confirmPassword || password != confirmPassword) {
      notify('Passwords do not match');
      return;
    }

    let axios = Axios.create({
      responseType: "json",
      headers: {
        'Content-Type': "application/json",
      },
    });

    axios.post(`/api/login/reset-password`, { email: email, code: code, password: password })
      .then(result => {
        if (result.data.success) {
          setSubmitted(true);
        } else {
          notify(result.data.error);
        }
      });
  }

  return (
    <>
      {submitted ?
        <div className={styles.root}>
          <Tag appearance="brand" style={{marginBottom: 10}}>
            Password has been reset. Please log in
          </Tag>
          <Button appearance='primary' onClick={() => navigate('/login')}>Login</Button>
        </div>
        :
        <div className={styles.root}>
          <Field className={styles.field}>
            <Input value={password} onChange={(ev, data) => setPassword(data.value)} placeholder='Enter a new Password' type='password' />
          </Field>
          <Field className={styles.field}>
            <Input value={confirmPassword} onChange={(ev, data) => setConfirmPassword(data.value)} placeholder='Confirm new Password' type='password' />
          </Field>
          <Button appearance='primary' onClick={submit}>Reset Password</Button>
          <Button onClick={() => navigate('/login')} style={{ color: 'white' }} appearance='transparent'>Login</Button>
        </div>
      }
    </>
  );
}

export default ResetPassword;
