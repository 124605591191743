import { Navigate, RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import Login from "./login";
import ForgotPassword from "./forgot-password";
import { FluentProvider, Title2, Toast, ToastTitle, Toaster, makeStyles, useId, useToastController, webLightTheme } from "@fluentui/react-components";
import ResetPassword from "./reset-password";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    backgroundImage: "url('https://forslafilestorage.blob.core.windows.net/static-site/login-page.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
});

function App() {
  const styles = useStyles();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = (message) =>
    dispatchToast(
      <Toast appearance='inverted'>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { intent: 'warning', position: 'top' }
    );

  const router = createBrowserRouter([
    {
      path: "/reset-password",
      element: <ResetPassword notify={notify} />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword notify={notify} />,
    },
    {
      path: "/login",
      element: <Login notify={notify} />,
    },
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
  ]);

  return (
    <div>
      <FluentProvider theme={webLightTheme}>
        <Title2 style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => window.location.replace('/')}>Forsla Portal</Title2>
        <div className={styles.root}>
          <Toaster toasterId={toasterId} />
          <RouterProvider router={router} />
        </div>
      </FluentProvider>
    </div>
  );
}

export default App;