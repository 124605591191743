import { Button, makeStyles, Field, Input, Tag } from '@fluentui/react-components';
import Axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    marginBottom: '10px',
  },
});

function ForgotPassword({ notify }) {
  const navigate = useNavigate();
  const styles = useStyles();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    if (!email) {
      notify('Email is Required');
      return;
    }

    let axios = Axios.create({
      responseType: "json",
      headers: {
        'Content-Type': "application/json",
      },
    });

    axios.post(`/api/login/forgot-password?toEmail=${email}`)
      .then(result => {
        if (result.data.success) {
          setSubmitted(true);
        } else {
          notify(result.data.error);
        }
      });
  }

  return (
    <>
      {submitted ?
        <div className={styles.root}>
          <Tag appearance="brand" style={{marginBottom: 10}}>
            Please check your email for a password reset email
          </Tag>
          <Button appearance='primary' onClick={() => navigate('/login')}>Login</Button>
        </div>
        :
        <div className={styles.root}>
          <Field className={styles.field}>
            <Input value={email} onChange={(ev, data) => setEmail(data.value)} placeholder='Enter your Email' type='email' />
          </Field>
          <Button appearance='primary' onClick={submit}>Submit</Button>
          <Button onClick={() => navigate('/login')} style={{ color: 'white' }} appearance='transparent'>Login</Button>
        </div>
      }
    </>
  );
}

export default ForgotPassword;
